<template>
  <el-row type="flex" :class="['alert', `alert--${type}`, { 'alert--inline': inline }]">
    <el-col class="alert__content" :span="$slots.button && 20">
      <icon :name="icons[type]" width="20" height="20" class="alert__icon" />
      <div class="alert__text">
        <h6>{{ title }}</h6>
        <span v-html="description" />
      </div>
    </el-col>
    <el-col v-if="$slots.button" class="alert__action" :span="4">
      <slot name="button"></slot>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Alert',

  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      validator: val => ['danger', 'warning', 'info'].includes(val),
      default: 'warning',
    },
  },

  data() {
    return {
      icons: {
        danger: 'close-circle',
        warning: 'warning-circle',
        info: 'help-circle',
      },
    }
  },
}
</script>

<style lang="scss">
.alert {
  position: relative;
  height: 80px;
  display: flex;
  padding: 12px 24px;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: $spacing-l;

  &::after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 2px;
  }

  &--inline {
    border-radius: 0;
  }
  &--danger {
    &::after {
      background-color: $color-danger;
    }
    .primary {
      fill: $color-danger;
    }
  }
  &--warning {
    &::after {
      background-color: $color-warning;
    }
    .primary {
      fill: $color-warning;
    }
  }
  &--info {
    &::after {
      background-color: $color-primary;
    }
    .primary {
      fill: $color-primary;
    }
    .secondary {
      fill: white;
    }
  }
}
.alert__content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.alert__text {
  margin-left: $spacing-l;
  span {
    opacity: 0.8;
  }
}
.alert__action {
  text-align: right;
}
.alert--inline {
  box-shadow: none;
  border-bottom: 1px solid $color-layout-light-03;
}
.alert__icon {
  position: absolute;
  top: $spacing-s;
  left: $spacing-m;
}
</style>
