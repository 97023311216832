<template>
  <section class="auth">
    <el-row type="flex" class="auth__container">
      <el-col :span="12" class="auth__data">
        <el-row type="flex" justify="center" align="middle">
          <el-col class="auth__data__container">
            <el-form
              ref="form"
              :model="formData"
              :rules="formRules"
              label-position="top"
              label-width="100px"
              :show-message="true"
              class="auth__form"
              size="small"
            >
              <div key="title" class="auth__form__title__container">
                <img
                  src="~@/assets/images/hybrid_warehouse_logo.svg"
                  alt="hybrid logo"
                  height="32px"
                  width="32px"
                />
                <span class="auth__form__title"><b>hybrid</b> warehouse</span>
              </div>

              <alert
                v-if="errorMessages[formType]"
                :title="errorMessages[formType]"
                class="auth__form__message"
              />
              <alert v-if="successMessage" :title="successMessage" class="auth__form__message" />

              <el-form-item
                v-for="(item, type) in formItems[formType]"
                :key="item.label || item.text"
                :class="item.class"
                :label="item.label"
                :prop="item.name"
              >
                <component
                  :is="item.is"
                  v-model.trim="formData[item.name]"
                  v-bind="item.attrs"
                  :disabled="checkDisabled(item, type)"
                  :loading="checkLoading(item)"
                  v-on="item.on"
                  >{{ item.text }}
                </component>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12" class="auth__gallery">
        <el-row type="flex" justify="center" align="middle">
          <el-col class="auth__gallery__container">
            <el-carousel trigger="click" height="80vh" arrow="never">
              <el-carousel-item
                v-for="section in gallerySections"
                :key="section.name"
                class="auth__gallery__item"
              >
                <h3 class="auth__gallery__title">{{ section.title }}</h3>
                <p class="auth__gallery__text">{{ section.text }}</p>
                <el-image class="auth__gallery__image" :src="section.image" fit="contain" />
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import Alert from '@/components/Alert'
import { mapActions, mapState, mapMutations } from 'vuex'
import { setTimeout } from 'timers'

export default {
  name: 'Auth',

  components: {
    Alert,
  },

  metaInfo() {
    return {
      title: this.$i18n.t(`titles.${this.formType}`),
    }
  },

  data() {
    return {
      formType: 'login',

      formData: {
        name: '',
        email: '',
        company: '',
        password: '',
        phone: '',
        repeatPassword: '',
      },

      formRules: {
        name: {
          required: true,
          message: this.$i18n.t('user.name.required'),
          trigger: 'blur',
        },
        email: {
          required: true,
          type: 'email',
          message: this.$i18n.t('user.email.required'),
          trigger: 'blur',
        },
        phone: {
          required: true,
          message: this.$i18n.t('user.phone.required'),
          trigger: 'blur',
        },
        company: [
          {
            required: true,
            message: this.$i18n.t('user.company.required'),
            trigger: 'blur',
          },
          {
            min: 3,
            max: 100,
            message: this.$i18n.t('user.company.invalidLength'),
            trigger: 'blur',
          },
        ],
        password: {
          required: true,
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error(this.$i18n.t('user.password.required')))
            } else if (
              this.formType === 'signup' &&
              (value.toString().length < 8 || value.toString().length > 100)
            ) {
              callback(new Error(this.$i18n.t('user.password.invalidLength')))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
        repeatPassword: {
          required: true,
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error(this.$i18n.t('user.repeatPassword.required')))
            } else if (value !== this.formData.password) {
              callback(new Error(this.$i18n.t('user.repeatPassword.notMatch')))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
      },

      formItems: {
        login: [
          {
            label: this.$i18n.t('user.email.label'),
            is: 'el-input',
            name: 'email',
            attrs: { type: 'email', name: 'email', autocomplete: 'on' },
          },
          {
            label: this.$i18n.t('user.password.label'),
            is: 'el-input',
            name: 'password',
            attrs: {
              type: 'password',
              name: 'password',
              autocomplete: 'current-password',
              'show-password': true,
            },
          },
          {
            is: 'el-button',
            attrs: { type: 'text', class: 'auth__form__forgot-btn el-button--stretch' },
            text: this.$i18n.t('forgot.question'),
            on: { click: this.setFormType.bind(this, 'forgot') },
          },
          {
            is: 'el-button',
            attrs: { type: 'primary', class: 'el-button--stretch' },
            class: 'auth__form__btn',
            text: this.$i18n.t('login'),
            on: { click: this.handleLogin },
          },
          {
            is: 'el-button',
            attrs: { type: 'default', class: 'el-button--stretch' },
            class: 'auth__form__btn',
            text: this.$i18n.t('signup'),
            on: { click: this.setFormType.bind(this, 'signup') },
          },
        ],
        signup: [
          {
            label: this.$i18n.t('user.name.label'),
            is: 'el-input',
            name: 'name',
          },
          {
            label: this.$i18n.t('user.email.label'),
            is: 'el-input',
            name: 'email',
            attrs: { type: 'email', name: 'email', autocomplete: 'on' },
          },
          {
            label: this.$i18n.t('user.phone.label'),
            is: 'el-input',
            name: 'phone',
          },
          {
            label: this.$i18n.t('user.company.label'),
            is: 'el-input',
            name: 'company',
          },
          {
            label: this.$i18n.t('user.password.label'),
            is: 'el-input',
            name: 'password',
            attrs: {
              type: 'password',
              name: 'password',
              autocomplete: 'new-password',
              'show-password': true,
            },
          },
          {
            label: this.$i18n.t('user.repeatPassword.label'),
            is: 'el-input',
            name: 'repeatPassword',
            attrs: {
              type: 'password',
              name: 'repeat-password',
              autocomplete: 'new-password',
              'show-password': true,
            },
          },
          {
            is: 'el-button',
            attrs: { type: 'primary', class: 'el-button--stretch' },
            class: 'auth__form__btn',
            text: this.$i18n.t('signup'),
            on: { click: this.handleSignup },
          },
          {
            is: 'el-button',
            attrs: { type: 'default', class: 'el-button--stretch' },
            class: 'auth__form__btn',
            text: this.$i18n.t('login'),
            on: { click: this.setFormType.bind(this, 'login') },
          },
        ],
        forgot: [
          { is: 'p', text: this.$i18n.t('forgot.instructions') },
          {
            label: this.$i18n.t('user.email.label'),
            is: 'el-input',
            name: 'email',
            attrs: { type: 'email', name: 'email', autocomplete: 'on' },
          },
          {
            is: 'el-button',
            attrs: { type: 'primary', class: 'el-button--stretch' },
            class: 'auth__form__btn',
            text: this.$i18n.t('forgot.send'),
            on: { click: this.handleForgot },
          },
          {
            is: 'el-button',
            attrs: { type: 'default', class: 'el-button--stretch' },
            class: 'auth__form__btn',
            text: this.$i18n.t('forgot.return'),
            on: { click: this.setFormType.bind(this, 'login') },
          },
        ],
        createAccount: [
          { is: 'p', text: this.$i18n.t('createAccount.instructions') },
          {
            label: this.$i18n.t('user.company.label'),
            is: 'el-input',
            name: 'company',
          },
          {
            label: this.$i18n.t('user.phone.label'),
            is: 'el-input',
            name: 'phone',
          },
          {
            is: 'el-button',
            attrs: { type: 'primary', class: 'el-button--stretch' },
            class: 'auth__form__btn',
            text: this.$i18n.t('createAccount.accept'),
            on: { click: this.handleCreateAccount },
          },
        ],
      },

      gallerySections: [
        {
          name: 'import',
          title: this.$i18n.t('gallery.import.title'),
          text: this.$i18n.t('gallery.import.text'),
          image: require('../assets/images/import.svg'),
        },
        {
          name: 'profiles',
          title: this.$i18n.t('gallery.profiles.title'),
          text: this.$i18n.t('gallery.profiles.text'),
          image: require('../assets/images/workspace.svg'),
        },
        {
          name: 'segments',
          title: this.$i18n.t('gallery.segments.title'),
          text: this.$i18n.t('gallery.segments.text'),
          image: require('../assets/images/usecase.svg'),
        },
        {
          name: 'export',
          title: this.$i18n.t('gallery.export.title'),
          text: this.$i18n.t('gallery.export.text'),
          image: require('../assets/images/dashboard.svg'),
        },
      ],

      successMessage: '',
      errorMessages: {
        login: '',
        signup: '',
        forgot: '',
      },
      disabledSubmits: {
        login: false,
        signup: false,
        forgot: false,
      },
      loading: false,
      resetTimeout: true,
    }
  },

  computed: {
    ...mapState('auth', ['resetTokenExpired', 'passwordResetSuccess']),
  },

  watch: {
    formData: {
      handler() {
        if (!this.resetTimeout) {
          this.resetForm()
        }
      },
      deep: true,
    },
  },

  created() {
    if (this.resetTokenExpired) {
      this.errorMessages.forgot = this.$i18n.t('passwordReset.tokenExpired')
      this.formType = 'forgot'
    } else if (this.passwordResetSuccess) {
      this.successMessage = this.$i18n.t('passwordReset.success')
      this.formType = 'login'
    }

    this.setResetTokenExpired(false)
    this.setPasswordResetSuccess(false)

    setTimeout(() => {
      this.resetTimeout = false
    }, 1000)
  },

  methods: {
    ...mapActions('auth', ['login', 'signup', 'forgotPassword', 'createAccount']),
    ...mapMutations('auth', ['setResetTokenExpired', 'setPasswordResetSuccess']),
    checkDisabled({ attrs, is }, type) {
      if (is === 'el-button') {
        return attrs.type === 'primary' ? this.disabledSubmits[type] : this.loading
      }
    },
    checkLoading({ attrs, is }) {
      if (is === 'el-button' && attrs.type === 'primary') {
        return this.loading
      }
    },
    resetForm() {
      this.errorMessages = {
        login: '',
        signup: '',
        forgot: '',
      }
      this.disabledSubmits = {
        login: false,
        signup: false,
        forgot: false,
      }
      this.successMessage = ''
    },
    setFormType(type) {
      this.formType = type
      this.resetForm()
      this.$refs.form.clearValidate()
    },
    async handleLogin() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          const { error, createAccount } = await this.login(this.formData)
          this.loading = false

          if (error) {
            if (error.status === 404) {
              this.errorMessages.login = this.$i18n.t('userNotFound')
            }
            this.disabledSubmits.login = true
            return false
          } else if (createAccount) {
            this.setFormType('createAccount')
          } else {
            this.$router.push('/')
          }
        } else {
          this.disabledSubmits.login = true
          return false
        }
      })
    },
    async handleSignup() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          const { error } = await this.signup(this.formData)

          if (error) {
            this.loading = false
            if (error.status === 304) {
              this.errorMessages.signup = this.$i18n.t('userAlreadyExists')
            }
            this.disabledSubmits.signup = true
            return false
          } else {
            const { error, createAccount } = await this.login(this.formData)
            this.loading = false

            if (error) {
              this.disabledSubmits.signup = true
              return false
            } else if (createAccount) {
              this.setFormType('createAccount')
            } else {
              this.$router.push('/')
            }
          }
        } else {
          this.disabledSubmits.signup = true
          return false
        }
      })
    },
    async handleForgot() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          const { error } = await this.forgotPassword(this.formData)
          this.loading = false

          if (error) {
            if (error.status === 404) {
              this.errorMessages.forgot = this.$i18n.t('emailNotFound')
            }
            this.disabledSubmits.forgot = true
            return false
          } else {
            this.formType = 'login'
            this.successMessage = this.$i18n.t('forgot.emailSent')
          }
        } else {
          this.disabledSubmits.forgot = true
          return false
        }
      })
    },
    async handleCreateAccount() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          const { error } = await this.createAccount(this.formData)
          this.loading = false

          if (error) {
            this.disabledSubmits.createAccount = true
            return false
          } else {
            this.$router.push('/')
          }
        } else {
          this.disabledSubmits.createAccount = true
          return false
        }
      })
    },
  },
}
</script>

<i18n>
{
  "en": {
    "titles": {
      "login": "Login",
      "signup": "Sign up",
      "forgot": "Forgot"
    },
    "login": "Login",
    "signup": "Sign up",
    "forgot": {
      "question": "Forgot password?",
      "instructions": "Enter email address associated with your account and we'll send you instructions for creating a new password",
      "return": "Back",
      "send": "Send",
      "emailSent": "Password reset instructions have been sent to your email address"
    },
    "gallery": {
      "import": {
        "title": "Add data from multiple sources",
        "text": "Hybrid Warehouse allows you to customize automated data import from chosen sources of data and store it in the personal cloud storage"
      },
      "profiles": {
        "title": "Manage customer profiles",
        "text": "You can collect, browse, edit and group profiles with Hybrid Warehouse"
      },
      "segments": {
        "title": "Build segments",
        "text": "Segment constructor of Hybrid Warehouse provides ample opportunities for collection of complex segments from profiles"
      },
      "export": {
        "title": "Export segments to ad networks",
        "text": "Built segments can be easily exported to ad networks. Hybrid Warehouse will take care of it!"
      }
    },
    "createAccount": {
      "instructions": "Please provide additional information to create an account in Hybrid Warehouse",
      "accept": "Create Account"
    },
    "userNotFound": "Wrong email or password",
    "userAlreadyExists": "User with given email address already exists",
    "emailNotFound": "User with given email address not found",
    "passwordReset": {
      "tokenExpired": "The password reset link has expired",
      "success": "The password has been changed successfully"
    }
  },
  "ru": {
    "titles": {
      "login": "Вход",
      "signup": "Регистрация",
      "forgot": "Забыли пароль"
    },
    "login": "Войти",
    "signup": "Зарегистрироваться",
    "forgot": {
      "question": "Забыли пароль?",
      "instructions": "Введите электронную почту, привязанную к вашему аккаунту, и мы отправим вам инструкции по созданию нового пароля",
      "return": "Назад",
      "send": "Отправить",
      "emailSent": "Письмо с инструкцией по созданию нового пароля отправлено на ваш адрес электронной почты"
    },
    "gallery": {
      "import": {
        "title": "Добавляйте данные из разных источников",
        "text": "Hybrid Warehouse позволяет настраивать автоматическое получение данных из выбранных источников и хранить их в персональном облаке"
      },
      "profiles": {
        "title": "Управляйте профилями клиентов",
        "text": "С Hybrid Warehouse вы можете собирать, просматривать, редактировать и группировать профили"
      },
      "segments": {
        "title": "Создавайте сегменты",
        "text": "Конструктор сегментов Hybrid Warehouse предоставляет широкие возможности для сбора сложных сегментов из профилей"
      },
      "export": {
        "title": "Выгружайте сегменты в рекламные сети",
        "text": "Собранные сегменты могут быть легко выгружены в рекламные сети, Hybrid Warehouse позаботится об этом!"
      }
    },
    "createAccount": {
      "instructions": "Пожалуйста, укажите дополнительную информацию для создания аккаунта в Hybrid Warehouse",
      "accept": "Создать аккаунт"
    },
    "userNotFound": "Неверно указан email или пароль",
    "userAlreadyExists": "Пользователь с указанным адресом электронной почты уже зарегистрирован",
    "emailNotFound": "Пользователь с указанным адресом электронной почты не найден",
    "passwordReset": {
      "tokenExpired": "Ссылка для создания нового пароля более не действительна",
      "success": "Пароль успешно изменен"
    }
  }
}
</i18n>

<style lang="scss">
.auth {
  background: $color-primary;
  display: flex;
  justify-content: center;
  height: 100%;
}
.auth__container {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1200px;
}
.auth__data,
.auth__gallery {
  z-index: 10;
}
.auth__data__container {
  max-width: 400px;
}
.auth__form {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 32px;
  z-index: 5;
}
.auth__form__title {
  font-size: 24px;
  b {
    font-weight: 600;
  }
}
.auth__form__title__container {
  display: inline-flex;
  align-items: center;
  margin-bottom: $spacing-2xl;
  img {
    margin-right: $spacing-s;
  }
}
.auth__form__message {
  width: 100%;
  &.alert {
    height: auto;
    padding: $spacing-s $spacing-l;
  }
  h6 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
  }
}
.auth__form__forgot-btn {
  text-align: right;
  padding: $spacing-xs 0;
}
.el-form-item--small.el-form-item.auth__form__btn {
  margin-bottom: $spacing-xs;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.auth__gallery {
  z-index: 1;
}
.auth__gallery__container {
  max-height: 80%;
}
.auth__gallery__image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  max-height: 550px;
  margin-bottom: $spacing-m;
}
.auth__gallery__item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
}
.auth__gallery__title {
  font-size: 32px;
  line-height: 1.3;
  margin-bottom: $spacing-s;
}
.auth__gallery__text {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: $spacing-3xl;
}
@media only screen and (max-height: 700px) {
  .auth__decorative-element {
    &::after {
      width: 200%;
      height: 200%;
      top: -60%;
    }
  }
}
</style>
